/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import e from "../../core/Error.js";
import has from "../../core/has.js";
import r from "../../core/Logger.js";
const o = () => r.getLogger("esri.views.webgl.checkWebGLError");
function t(e, r) {
  switch (r) {
    case e.INVALID_ENUM:
      return "Invalid Enum. An unacceptable value has been specified for an enumerated argument.";
    case e.INVALID_VALUE:
      return "Invalid Value. A numeric argument is out of range.";
    case e.INVALID_OPERATION:
      return "Invalid Operation. The specified command is not allowed for the current state.";
    case e.INVALID_FRAMEBUFFER_OPERATION:
      return "Invalid Framebuffer operation. The currently bound framebuffer is not framebuffer complete when trying to render to or to read from it.";
    case e.OUT_OF_MEMORY:
      return "Out of memory. Not enough memory is left to execute the command.";
    case e.CONTEXT_LOST_WEBGL:
      return "WebGL context has been lost";
    default:
      return "Unknown error";
  }
}
const n = !!has("enable-feature:webgl-debug");
function a() {
  return n;
}
function c() {
  return n;
}
function u(r) {
  if (a()) {
    const n = r.getError();
    if (n) {
      const a = t(r, n),
        c = new Error().stack;
      o().error(new e("webgl-error", "WebGL error occurred", {
        message: a,
        stack: c
      }));
    }
  }
}
export { u as checkWebGLError, n as hasFeatureFlagWebGLDebug, a as webglDebugEnabled, c as webglValidateShadersEnabled };