/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { SizedPixelFormat as s, PixelFormat as e } from "./enums.js";
import { TextureDescriptor as t } from "./TextureDescriptor.js";
class a extends t {
  constructor(t, a) {
    switch (super(), this.context = t, Object.assign(this, a), this.internalFormat) {
      case s.R16F:
      case s.R16I:
      case s.R16UI:
      case s.R32F:
      case s.R32I:
      case s.R32UI:
      case s.R8_SNORM:
      case s.R8:
      case s.R8I:
      case s.R8UI:
        this.pixelFormat = e.RED;
    }
  }
  static validate(s, e) {
    return new a(s, e);
  }
}
export { a as ValidatedTextureDescriptor };