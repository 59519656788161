/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import t from "../../core/Error.js";
import "../../core/has.js";
import { checkWebGLError as e } from "./checkWebGLError.js";
import { TextureType as i, ResourceType as r, TextureSamplingMode as s, PixelFormat as o, PixelType as a, SizedPixelFormat as n, CompressedTextureFormat as l } from "./enums.js";
import { GLObjectType as h } from "./GLObjectType.js";
import { estimateMemory as p } from "./TextureDescriptor.js";
import { ValidatedTextureDescriptor as d } from "./ValidatedTextureDescriptor.js";
const _ = null;
let m = class l {
  constructor(e, r = null, s = null) {
    if (this.type = h.Texture, this._glName = null, this._samplingModeDirty = !1, this._wrapModeDirty = !1, this._wasImmutablyAllocated = !1, "context" in e) this._descriptor = e, s = r;else {
      const i = d.validate(e, r);
      if (!i) throw new t("Texture descriptor invalid");
      this._descriptor = i;
    }
    this._descriptor.target === i.TEXTURE_CUBE_MAP ? this._setDataCubeMap(s) : this.setData(s);
  }
  get glName() {
    return this._glName;
  }
  get descriptor() {
    return this._descriptor;
  }
  get usedMemory() {
    return p(this._descriptor);
  }
  get isDirty() {
    return this._samplingModeDirty || this._wrapModeDirty;
  }
  dispose() {
    this._glName && this._descriptor.context.instanceCounter.decrement(r.Texture, this), this._descriptor.context.gl && this._glName && (this._descriptor.context.unbindTexture(this), this._descriptor.context.gl.deleteTexture(this._glName), this._glName = null);
  }
  release() {
    this.dispose();
  }
  resize(e, r) {
    const s = this._descriptor;
    if (s.width !== e || s.height !== r) {
      if (this._wasImmutablyAllocated) throw new t("Immutable textures can't be resized!");
      s.width = e, s.height = r, this._descriptor.target === i.TEXTURE_CUBE_MAP ? this._setDataCubeMap(null) : this.setData(null);
    }
  }
  _setDataCubeMap(t = null) {
    for (let e = i.TEXTURE_CUBE_MAP_POSITIVE_X; e <= i.TEXTURE_CUBE_MAP_NEGATIVE_Z; e++) this._setData(t, e);
  }
  setData(t) {
    this._setData(t);
  }
  _setData(i, s) {
    const o = this._descriptor.context?.gl;
    if (!o) return;
    e(o), this._glName || (this._glName = o.createTexture(), this._glName && this._descriptor.context.instanceCounter.increment(r.Texture, this));
    const a = this._descriptor,
      n = s ?? a.target,
      h = f(n),
      p = this._descriptor.context.bindTexture(this, l.TEXTURE_UNIT_FOR_UPDATES);
    this._descriptor.context.setActiveTexture(l.TEXTURE_UNIT_FOR_UPDATES), c(a), this._configurePixelStorage(), e(o);
    const d = this._deriveInternalFormat();
    if (I(i)) {
      let t = "width" in i ? i.width : i.codedWidth,
        r = "height" in i ? i.height : i.codedHeight;
      const s = 1;
      i instanceof HTMLVideoElement && (t = i.videoWidth, r = i.videoHeight), a.width && a.height, h && a.depth, a.isImmutable && !this._wasImmutablyAllocated && this._texStorage(n, d, a.hasMipmap, t, r, s), this._texImage(n, 0, d, t, r, s, i), e(o), a.hasMipmap && (this.generateMipmap(), e(o)), a.width || (a.width = t), a.height || (a.height = r), h && !a.depth && (a.depth = s);
    } else {
      const {
        width: r,
        height: s,
        depth: l
      } = a;
      if (null == r || null == s) throw new t("Width and height must be specified!");
      if (h && null == l) throw new t("Depth must be specified!");
      if (a.isImmutable && !this._wasImmutablyAllocated && this._texStorage(n, d, a.hasMipmap, r, s, l), M(i)) {
        const e = i.levels,
          h = R(n, r, s, l),
          p = Math.min(h - 1, e.length - 1);
        o.texParameteri(a.target, this._descriptor.context.gl.TEXTURE_MAX_LEVEL, p);
        const _ = d;
        if (!x(_)) throw new t("Attempting to use compressed data with an uncompressed format!");
        this._forEachMipmapLevel((t, i, r, s) => {
          const o = e[Math.min(t, e.length - 1)];
          this._compressedTexImage(n, t, _, i, r, s, o);
        }, p);
      } else this._texImage(n, 0, d, r, s, l, i), e(o), a.hasMipmap && this.generateMipmap();
    }
    u(o, this._descriptor), T(o, this._descriptor), E(this._descriptor.context, this._descriptor), e(o), this._descriptor.context.bindTexture(p, l.TEXTURE_UNIT_FOR_UPDATES);
  }
  updateData(e, i, r, s, o, a, n = 0) {
    a || console.error("An attempt to use uninitialized data!"), this._glName || console.error("An attempt to update uninitialized texture!");
    const h = this._descriptor,
      p = this._deriveInternalFormat(),
      {
        context: d,
        pixelFormat: _,
        dataType: m,
        target: c,
        isImmutable: u
      } = h;
    if (u && !this._wasImmutablyAllocated) throw new t("Cannot update immutable texture before allocation!");
    const T = d.bindTexture(this, l.TEXTURE_UNIT_FOR_UPDATES, !0);
    (i < 0 || r < 0 || i + s > h.width || r + o > h.height) && console.error("An attempt to update out of bounds of the texture!"), this._configurePixelStorage();
    const {
      gl: E
    } = d;
    n && E.pixelStorei(E.UNPACK_SKIP_ROWS, n), I(a) ? E.texSubImage2D(c, e, i, r, s, o, _, m, a) : M(a) ? E.compressedTexSubImage2D(c, e, i, r, s, o, p, a.levels[e]) : E.texSubImage2D(c, e, i, r, s, o, _, m, a), n && E.pixelStorei(E.UNPACK_SKIP_ROWS, 0), d.bindTexture(T, l.TEXTURE_UNIT_FOR_UPDATES);
  }
  updateData3D(e, i, r, s, o, a, n, h) {
    h || console.error("An attempt to use uninitialized data!"), this._glName || console.error("An attempt to update uninitialized texture!");
    const p = this._descriptor,
      d = this._deriveInternalFormat(),
      {
        context: _,
        pixelFormat: m,
        dataType: c,
        isImmutable: u,
        target: T
      } = p;
    if (u && !this._wasImmutablyAllocated) throw new t("Cannot update immutable texture before allocation!");
    f(T) || console.warn("Attempting to set 3D texture data on a non-3D texture");
    const E = _.bindTexture(this, l.TEXTURE_UNIT_FOR_UPDATES);
    _.setActiveTexture(l.TEXTURE_UNIT_FOR_UPDATES), (i < 0 || r < 0 || s < 0 || i + o > p.width || r + a > p.height || s + n > p.depth) && console.error("An attempt to update out of bounds of the texture!"), this._configurePixelStorage();
    const {
      gl: g
    } = _;
    if (M(h)) h = h.levels[e], g.compressedTexSubImage3D(T, e, i, r, s, o, a, n, d, h);else {
      const t = h;
      g.texSubImage3D(T, e, i, r, s, o, a, n, m, c, t);
    }
    _.bindTexture(E, l.TEXTURE_UNIT_FOR_UPDATES);
  }
  generateMipmap() {
    const e = this._descriptor;
    if (!e.hasMipmap) {
      if (this._wasImmutablyAllocated) throw new t("Cannot add mipmaps to immutable texture after allocation");
      e.hasMipmap = !0, this._samplingModeDirty = !0, c(e);
    }
    e.samplingMode === s.LINEAR ? (this._samplingModeDirty = !0, e.samplingMode = s.LINEAR_MIPMAP_NEAREST) : e.samplingMode === s.NEAREST && (this._samplingModeDirty = !0, e.samplingMode = s.NEAREST_MIPMAP_NEAREST);
    const i = this._descriptor.context.bindTexture(this, l.TEXTURE_UNIT_FOR_UPDATES);
    this._descriptor.context.setActiveTexture(l.TEXTURE_UNIT_FOR_UPDATES), this._descriptor.context.gl.generateMipmap(e.target), this._descriptor.context.bindTexture(i, l.TEXTURE_UNIT_FOR_UPDATES);
  }
  clearMipmap() {
    const e = this._descriptor;
    if (e.hasMipmap) {
      if (this._wasImmutablyAllocated) throw new t("Cannot delete mipmaps to immutable texture after allocation");
      e.hasMipmap = !1, this._samplingModeDirty = !0, c(e);
    }
    e.samplingMode === s.LINEAR_MIPMAP_NEAREST ? (this._samplingModeDirty = !0, e.samplingMode = s.LINEAR) : e.samplingMode === s.NEAREST_MIPMAP_NEAREST && (this._samplingModeDirty = !0, e.samplingMode = s.NEAREST);
  }
  setSamplingMode(t) {
    t !== this._descriptor.samplingMode && (this._descriptor.samplingMode = t, this._samplingModeDirty = !0);
  }
  setWrapMode(t) {
    t !== this._descriptor.wrapMode && (this._descriptor.wrapMode = t, c(this._descriptor), this._wrapModeDirty = !0);
  }
  applyChanges() {
    const t = this._descriptor,
      e = t.context.gl;
    this._samplingModeDirty && (u(e, t), this._samplingModeDirty = !1), this._wrapModeDirty && (T(e, t), this._wrapModeDirty = !1);
  }
  _deriveInternalFormat() {
    if (null != this._descriptor.internalFormat) return this._descriptor.internalFormat === o.DEPTH_STENCIL && (this._descriptor.internalFormat = o.DEPTH24_STENCIL8), this._descriptor.internalFormat;
    switch (this._descriptor.dataType) {
      case a.FLOAT:
        switch (this._descriptor.pixelFormat) {
          case o.RGBA:
            return this._descriptor.internalFormat = n.RGBA32F;
          case o.RGB:
            return this._descriptor.internalFormat = n.RGB32F;
          default:
            throw new t("Unable to derive format");
        }
      case a.UNSIGNED_BYTE:
        switch (this._descriptor.pixelFormat) {
          case o.RGBA:
            return this._descriptor.internalFormat = n.RGBA8;
          case o.RGB:
            return this._descriptor.internalFormat = n.RGB8;
        }
    }
    return this._descriptor.internalFormat = this._descriptor.pixelFormat === o.DEPTH_STENCIL ? o.DEPTH24_STENCIL8 : this._descriptor.pixelFormat;
  }
  _configurePixelStorage() {
    const t = this._descriptor.context.gl,
      {
        unpackAlignment: e,
        flipped: i,
        preMultiplyAlpha: r
      } = this._descriptor;
    t.pixelStorei(t.UNPACK_ALIGNMENT, e), t.pixelStorei(t.UNPACK_FLIP_Y_WEBGL, i ? 1 : 0), t.pixelStorei(t.UNPACK_PREMULTIPLY_ALPHA_WEBGL, r ? 1 : 0);
  }
  _texStorage(e, i, r, s, o, a) {
    const {
      gl: n
    } = this._descriptor.context;
    if (!g(i)) throw new t("Immutable textures must have a sized internal format");
    if (!this._descriptor.isImmutable) return;
    const l = r ? R(e, s, o, a) : 1;
    if (f(e)) {
      if (null == a) throw new t("Missing depth dimension for 3D texture upload");
      n.texStorage3D(e, l, i, s, o, a);
    } else n.texStorage2D(e, l, i, s, o);
    this._wasImmutablyAllocated = !0;
  }
  _texImage(e, i, r, s, o, a, n) {
    const l = this._descriptor.context.gl,
      h = f(e),
      {
        isImmutable: p,
        pixelFormat: d,
        dataType: _
      } = this._descriptor;
    if (p) {
      if (null != n) {
        const r = n;
        if (h) {
          if (null == a) throw new t("Missing depth dimension for 3D texture upload");
          l.texSubImage3D(e, i, 0, 0, 0, s, o, a, d, _, r);
        } else l.texSubImage2D(e, i, 0, 0, s, o, d, _, r);
      }
    } else {
      const p = n;
      if (h) {
        if (null == a) throw new t("Missing depth dimension for 3D texture upload");
        l.texImage3D(e, i, r, s, o, a, 0, d, _, p);
      } else l.texImage2D(e, i, r, s, o, 0, d, _, p);
    }
  }
  _compressedTexImage(e, i, r, s, o, a, n) {
    const l = this._descriptor.context.gl,
      h = f(e);
    if (this._descriptor.isImmutable) {
      if (null != n) if (h) {
        if (null == a) throw new t("Missing depth dimension for 3D texture upload");
        l.compressedTexSubImage3D(e, i, 0, 0, 0, s, o, a, r, n);
      } else l.compressedTexSubImage2D(e, i, 0, 0, s, o, r, n);
    } else if (h) {
      if (null == a) throw new t("Missing depth dimension for 3D texture upload");
      l.compressedTexImage3D(e, i, r, s, o, a, 0, n);
    } else l.compressedTexImage2D(e, i, r, s, o, 0, n);
  }
  _forEachMipmapLevel(e, r = 1 / 0) {
    let {
      width: s,
      height: o,
      depth: a,
      hasMipmap: n,
      target: l
    } = this._descriptor;
    const h = l === i.TEXTURE_3D;
    if (null == s || null == o || h && null == a) throw new t("Missing texture dimensions for mipmap calculation");
    for (let t = 0; e(t, s, o, a), n && (1 !== s || 1 !== o || h && 1 !== a) && !(t >= r); ++t) s = Math.max(1, s >> 1), o = Math.max(1, o >> 1), h && (a = Math.max(1, a >> 1));
  }
};
function c(t) {
  (null != t.width && t.width < 0 || null != t.height && t.height < 0 || null != t.depth && t.depth < 0) && console.error("Negative dimension parameters are not allowed!");
}
function u(t, e) {
  let i = e.samplingMode,
    r = e.samplingMode;
  i === s.LINEAR_MIPMAP_NEAREST || i === s.LINEAR_MIPMAP_LINEAR ? (i = s.LINEAR, e.hasMipmap || (r = s.LINEAR)) : i !== s.NEAREST_MIPMAP_NEAREST && i !== s.NEAREST_MIPMAP_LINEAR || (i = s.NEAREST, e.hasMipmap || (r = s.NEAREST)), t.texParameteri(e.target, t.TEXTURE_MAG_FILTER, i), t.texParameteri(e.target, t.TEXTURE_MIN_FILTER, r);
}
function T(t, e) {
  "number" == typeof e.wrapMode ? (t.texParameteri(e.target, t.TEXTURE_WRAP_S, e.wrapMode), t.texParameteri(e.target, t.TEXTURE_WRAP_T, e.wrapMode)) : (t.texParameteri(e.target, t.TEXTURE_WRAP_S, e.wrapMode.s), t.texParameteri(e.target, t.TEXTURE_WRAP_T, e.wrapMode.t));
}
function E(t, e) {
  const i = t.capabilities.textureFilterAnisotropic;
  if (!i) return;
  t.gl.texParameterf(e.target, i.TEXTURE_MAX_ANISOTROPY, e.maxAnisotropy ?? 1);
}
function g(t) {
  return t in n;
}
function x(t) {
  return t in l;
}
function M(t) {
  return null != t && "type" in t && "compressed" === t.type;
}
function A(t) {
  return null != t && "byteLength" in t;
}
function I(t) {
  return null != t && !M(t) && !A(t);
}
function f(t) {
  return t === i.TEXTURE_3D || t === i.TEXTURE_2D_ARRAY;
}
function R(t, e, r, s = 1) {
  let o = Math.max(e, r);
  return t === i.TEXTURE_3D && (o = Math.max(o, s)), Math.round(Math.log(o) / Math.LN2) + 1;
}
m.TEXTURE_UNIT_FOR_UPDATES = 0;
export { m as Texture, _ as tracer };