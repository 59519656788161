/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import "../../core/has.js";
import { webglDebugEnabled as e } from "./checkWebGLError.js";
import { BufferType as r, CompressedTextureFormat as t, SizedPixelFormat as s, RenderbufferFormat as a, PixelFormat as c } from "./enums.js";
import { getDataTypeBytes as n } from "./getDataTypeBytes.js";
function o(e) {
  const r = e.gl;
  switch (r.getError()) {
    case r.NO_ERROR:
      return null;
    case r.INVALID_ENUM:
      return "An unacceptable value has been specified for an enumerated argument";
    case r.INVALID_VALUE:
      return "An unacceptable value has been specified for an argument";
    case r.INVALID_OPERATION:
      return "The specified command is not allowed for the current state";
    case r.INVALID_FRAMEBUFFER_OPERATION:
      return "The currently bound framebuffer is not framebuffer complete";
    case r.OUT_OF_MEMORY:
      return "Not enough memory is left to execute the command";
    case r.CONTEXT_LOST_WEBGL:
      return "WebGL context is lost";
  }
  return "Unknown error";
}
function i(e, r) {
  return (e.vertexBuffers.get(r)?.usedMemory ?? 0) / R(e.layout.get(r));
}
function R(e) {
  return e[0].stride;
}
function E(r, t, s, a, c = 0) {
  const i = r.gl;
  r.bindBuffer(s);
  for (const R of a) {
    const s = t.get(R.name);
    if (null == s) {
      console.warn(`There is no location for vertex attribute '${R.name}' defined.`);
      continue;
    }
    const a = c * R.stride;
    if (R.count <= 4) i.vertexAttribPointer(s, R.count, R.type, R.normalized, R.stride, R.offset + a), i.enableVertexAttribArray(s), R.divisor > 0 && r.gl.vertexAttribDivisor(s, R.divisor);else if (9 === R.count) for (let e = 0; e < 3; e++) i.vertexAttribPointer(s + e, 3, R.type, R.normalized, R.stride, R.offset + 12 * e + a), i.enableVertexAttribArray(s + e), R.divisor > 0 && r.gl.vertexAttribDivisor(s + e, R.divisor);else if (16 === R.count) for (let e = 0; e < 4; e++) i.vertexAttribPointer(s + e, 4, R.type, R.normalized, R.stride, R.offset + 16 * e + a), i.enableVertexAttribArray(s + e), R.divisor > 0 && r.gl?.vertexAttribDivisor(s + e, R.divisor);else console.error("Unsupported vertex attribute element count: " + R.count);
    if (e()) {
      const e = o(r),
        t = n(R.type),
        s = R.offset,
        c = Math.round(t / s) !== t / s ? `. Offset not a multiple of stride. DataType requires ${t} bytes, but descriptor has an offset of ${s}` : "";
      e && console.error(`Unable to bind vertex attribute "${R.name}" with baseInstanceOffset ${a}${c}:`, e, R);
    }
  }
}
function _(e, t, s, a) {
  const c = e.gl;
  e.bindBuffer(s);
  for (const r of a) {
    const s = t.get(r.name);
    if (r.count <= 4) c.disableVertexAttribArray(s), r.divisor && r.divisor > 0 && e.gl?.vertexAttribDivisor(s, 0);else if (9 === r.count) for (let t = 0; t < 3; t++) c.disableVertexAttribArray(s + t), r.divisor && r.divisor > 0 && e.gl?.vertexAttribDivisor(s + t, 0);else if (16 === r.count) for (let t = 0; t < 4; t++) c.disableVertexAttribArray(s + t), r.divisor && r.divisor > 0 && e.gl?.vertexAttribDivisor(s + t, 0);else console.error("Unsupported vertex attribute element count: " + r.count);
  }
  e.unbindBuffer(r.ARRAY_BUFFER);
}
function u(e) {
  switch (e) {
    case c.ALPHA:
    case c.LUMINANCE:
    case c.RED:
    case c.RED_INTEGER:
    case s.R8:
    case s.R8I:
    case s.R8UI:
    case s.R8_SNORM:
    case a.STENCIL_INDEX8:
      return 1;
    case c.LUMINANCE_ALPHA:
    case c.RG:
    case c.RG_INTEGER:
    case s.RGBA4:
    case s.R16F:
    case s.R16I:
    case s.R16UI:
    case s.RG8:
    case s.RG8I:
    case s.RG8UI:
    case s.RG8_SNORM:
    case s.RGB565:
    case s.RGB5_A1:
    case a.DEPTH_COMPONENT16:
      return 2;
    case c.DEPTH_COMPONENT:
    case c.RGB:
    case c.RGB_INTEGER:
    case s.RGB8:
    case s.RGB8I:
    case s.RGB8UI:
    case s.RGB8_SNORM:
    case s.SRGB8:
    case a.DEPTH_COMPONENT24:
      return 3;
    case c.DEPTH_STENCIL:
    case c.DEPTH24_STENCIL8:
    case c.RGBA:
    case c.RGBA_INTEGER:
    case s.RGBA8:
    case s.R32F:
    case s.R11F_G11F_B10F:
    case s.RG16F:
    case s.R32I:
    case s.R32UI:
    case s.RG16I:
    case s.RG16UI:
    case s.RGBA8I:
    case s.RGBA8UI:
    case s.RGBA8_SNORM:
    case s.SRGB8_ALPHA8:
    case s.RGB9_E5:
    case s.RGB10_A2UI:
    case s.RGB10_A2:
    case a.DEPTH_STENCIL:
    case a.DEPTH_COMPONENT32F:
    case a.DEPTH24_STENCIL8:
      return 4;
    case a.DEPTH32F_STENCIL8:
      return 5;
    case s.RGB16F:
    case s.RGB16I:
    case s.RGB16UI:
      return 6;
    case s.RG32F:
    case s.RG32I:
    case s.RG32UI:
    case s.RGBA16F:
    case s.RGBA16I:
    case s.RGBA16UI:
      return 8;
    case s.RGB32F:
    case s.RGB32I:
    case s.RGB32UI:
      return 12;
    case s.RGBA32F:
    case s.RGBA32I:
    case s.RGBA32UI:
      return 16;
    case t.COMPRESSED_RGB_S3TC_DXT1_EXT:
    case t.COMPRESSED_RGBA_S3TC_DXT1_EXT:
      return .5;
    case t.COMPRESSED_RGBA_S3TC_DXT3_EXT:
    case t.COMPRESSED_RGBA_S3TC_DXT5_EXT:
      return 1;
    case t.COMPRESSED_R11_EAC:
    case t.COMPRESSED_SIGNED_R11_EAC:
    case t.COMPRESSED_RGB8_ETC2:
    case t.COMPRESSED_SRGB8_ETC2:
    case t.COMPRESSED_RGB8_PUNCHTHROUGH_ALPHA1_ETC2:
    case t.COMPRESSED_SRGB8_PUNCHTHROUGH_ALPHA1_ETC2:
      return .5;
    case t.COMPRESSED_RG11_EAC:
    case t.COMPRESSED_SIGNED_RG11_EAC:
    case t.COMPRESSED_RGBA8_ETC2_EAC:
    case t.COMPRESSED_SRGB8_ALPHA8_ETC2_EAC:
      return 1;
  }
  return 0;
}
export { E as bindVertexBufferLayout, u as getBytesPerElementFormat, o as getErrorString, R as getStride, _ as unbindVertexBufferLayout, i as vertexCount };