/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { TextureType as t, PixelFormat as i, PixelType as h, TextureSamplingMode as s, TextureWrapMode as a } from "./enums.js";
import { getBytesPerElementFormat as p } from "./Util.js";
class e {
  constructor(p = 0, e = p) {
    this.width = p, this.height = e, this.target = t.TEXTURE_2D, this.pixelFormat = i.RGBA, this.dataType = h.UNSIGNED_BYTE, this.samplingMode = s.LINEAR, this.wrapMode = a.REPEAT, this.maxAnisotropy = 1, this.flipped = !1, this.hasMipmap = !1, this.isOpaque = !1, this.unpackAlignment = 4, this.preMultiplyAlpha = !1, this.depth = 1, this.isImmutable = !1;
  }
}
function r(t) {
  return t.width <= 0 || t.height <= 0 ? 0 : Math.round(t.width * t.height * (t.hasMipmap ? 4 / 3 : 1) * (null == t.internalFormat ? 4 : p(t.internalFormat)));
}
export { e as TextureDescriptor, r as estimateMemory };