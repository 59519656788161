/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { DataType as e } from "./enums.js";
function s(s) {
  switch (s) {
    case e.BYTE:
    case e.UNSIGNED_BYTE:
      return 1;
    case e.SHORT:
    case e.UNSIGNED_SHORT:
    case e.HALF_FLOAT:
      return 2;
    case e.FLOAT:
    case e.INT:
    case e.UNSIGNED_INT:
      return 4;
  }
}
export { s as getDataTypeBytes };